import { getField, updateField } from 'vuex-map-fields';

const state = {
  // api_key: '54555hhjnncdhhb565839999393939393',
  // project_name: 'First Project',
};

const getters = {
  getField,
};

const actions = {
};

const mutations = {
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
