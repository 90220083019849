<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.8324 6.33268V3.83268C14.8324 3.61166 14.7446 3.3997 14.5883 3.24342C14.432 3.08714 14.22 2.99934 13.999 2.99934H5.66569C5.22366 2.99934 4.79974 3.17494 4.48718 3.4875C4.17462 3.80006 3.99902 4.22398 3.99902 4.66601M3.99902 4.66601C3.99902 5.10804 4.17462 5.53196 4.48718 5.84452C4.79974 6.15708 5.22366 6.33268 5.66569 6.33268H15.6657C15.8867 6.33268 16.0987 6.42047 16.2549 6.57676C16.4112 6.73304 16.499 6.945 16.499 7.16601V9.66601M3.99902 4.66601V14.666C3.99902 15.108 4.17462 15.532 4.48718 15.8445C4.79974 16.1571 5.22366 16.3327 5.66569 16.3327H15.6657C15.8867 16.3327 16.0987 16.2449 16.2549 16.0886C16.4112 15.9323 16.499 15.7204 16.499 15.4993V12.9993" stroke="#475569" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.666 10V13.3333H13.3327C12.8907 13.3333 12.4667 13.1577 12.1542 12.8452C11.8416 12.5326 11.666 12.1087 11.666 11.6667C11.666 11.2246 11.8416 10.8007 12.1542 10.4882C12.4667 10.1756 12.8907 10 13.3327 10H16.666Z" stroke="#475569" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  // props: ['width', 'height', 'fill']

}
</script>

<style>

</style>