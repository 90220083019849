import { getField, updateField } from 'vuex-map-fields';
import axios from 'axios';
import { useToast } from 'vue-toastification'

const toast = useToast()

const state = {
  testPhonesData: [],
  testPhonesMeta: {
    page: 1,
    per_page: 10,
    pageSize: 10,
    total: 0
  },
};

const getters = {
  getField,
};

const actions = {
  async GET_PHONES({commit, state}, params = { page: 1, per_page: 10 }) {
    commit('LoadingModule/commitChangeLoading', true, { root: true })

    await axios.get('/test-phones', { params: params })
      .then((res) => {
        commit('commitTestPhonesData', res.data.data)
        commit('commitTestPhonesMeta', res.data.meta)
      }).catch((e) => {
      }).finally(() => {
        commit('LoadingModule/commitChangeLoading', false, { root: true })
      })
  },
  async CREATE_PHONE({commit, state}, payload) {
    commit('LoadingModule/commitChangeLoading', true, { root: true })


    let response
    await axios.post('/test-phones', payload)
      .then((res) => {
        response = res
        toast.success('Successfully create phone')
        return res
      }).catch((e) => {
        if(e.response) {
          response = e.response.data
        }
        toast.error(e.response.data.error)
      }).finally(() => {
        commit('LoadingModule/commitChangeLoading', false, { root: true })
      })
    return response;
  },
  async UPDATE_PHONE({commit, state}, payload) {
    commit('LoadingModule/commitChangeLoading', true, { root: true })

    let response
    await axios.patch(`/test-phones/${payload.id}`, { phone: payload.phone, code: payload.code})
      .then((res) => {
        response = res
        toast.success('Successfully create phone')
        return res
      }).catch((e) => {
        if(e.response) {
          response = e.response.data
        }
        toast.error(e.response.data.error)
      }).finally(() => {
        commit('LoadingModule/commitChangeLoading', false, { root: true })
      })
    return response;
  },
  async DELETE_PHONES({commit, state}, id) {
    commit('LoadingModule/commitChangeLoading', true, { root: true })

    let response
    await axios.delete(`/test-phones/${id}`)
      .then((res) => {
        response = res
        toast.success('Successfully create phone')
        return res
      }).catch((e) => {
        if(e.response) {
          response = e.response.data
        }
        toast.error(e.response.data.error)
      }).finally(() => {
        commit('LoadingModule/commitChangeLoading', false, { root: true })
      })
    return response;
  },
};

const mutations = {
  updateField,
  commitTestPhonesData(state, data) {
    state.testPhonesData = data
  },
  commitTestPhonesMeta(state, meta) {
    state.testPhonesMeta = {
      page: meta.current_page,
      per_page: meta.per_page,
      pageSize: meta.per_page,
      total: meta.total
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
